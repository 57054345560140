import {Row, Input, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import React, {useRef} from "react";
import DraggableImageViewer from "../common/DraggableImageViewer";
import {config} from "../../config";


function OrchardRegisterForm(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const imgPickerRef = useRef(null);

    const onChangeHandler = (e) => {
        if (e.target) {
            const {name, value} = e.target;
            if(value.length <= 50 || (name === 'notes' && value.length <= 500)){
                props.setFormState({...props.formState, [name]: value});
            }
        } else {
            let arr = [];
            e.values.forEach((element) => {
                arr.push({
                    _id: element.value,
                    name: element.label,
                });
            });
            props.setFormState({...props.formState, [e.name]: arr});
        }
    };

    const onChangeHandlerFile = (e) => {
        let imgArr = [];

        for(let i=0; i<props.fileState.length; i++) {
            imgArr.push(props.fileState[i])
        }

        for(let i=0; i<e.target.files.length; i++) {
            if(imgArr.length < 5) {
                imgArr.push({_id: "", file: e.target.files[i]})
            }
        }
        props.setFileState(imgArr);
        e.target.value = null;
    }

    const onBackClick=()=>{
        if(props.plantParam) {
            history.push(`/system/${props.systemId}/plant/view/${props.plantParam}`)
        } else {
            if(props.categoryName) {
                history.push(`/system/${props.systemId}/register-entries/category/${props.categoryId}`)
            } else {
                history.push(`/system/${props.systemId}/register-entries/`)
            }
        }
    }

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : '',
            minHeight: '37px',
            maxHeight: '37px',
            boxShadow: 'none',
            border: '1px solid #ced4da',
            '&:active': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:focus': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:hover': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },

        }),

        multiValue: (provided) => ({
            ...provided,
            border: '#D5D5D5 solid 0.5px',
            borderRadius: '4px',
            backgroundColor: '#F8F9FA'
        }),

        multiValueLabel: (provided) => ({
            ...provided,
            color: '#74788D'
        }),

        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#74788D',
            width: state.isDisabled ? '0px' : '',
            '&:hover': {backgroundColor: '#F8F9FA', color: '#74788D'}

        }),


    }
    return (
        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0"
                                htmlFor="title">{t("registerEntry.form.field1.name")} {!props.isDisabled && '*'}</label>
                    </div>
                    <div className='form-limitation-container'>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            className="form-control input-disabled"
                            value={props.formState.title}
                            onChange={onChangeHandler}
                            required
                            placeholder={t("registerEntry.form.field1.placeholder")}
                            disabled={props.isDisabled}
                        />
                        {!props.isDisabled && <div>{props.formState.title.length}/50</div>}
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="areas">{t("registerEntry.form.field2.name")}</label>
                    </div>
                    <div>
                        <Select
                            isMulti
                            name="areas"
                            options={props.systemareas.map((el) => {
                                return {
                                    label: el.name,
                                    value: el._id
                                }
                            })}
                            className="basic-multi-select"
                            placeholder={t("registerEntry.form.field2.placeholder")}
                            components={{IndicatorSeparator: () => null}}
                            isClearable={false}
                            onChange={(e) => {
                                let event = {
                                    name: "areas",
                                    values: e,
                                };
                                onChangeHandler(event);
                            }}
                            value={props.formState.areas.map((item) => ({
                                value: item._id,
                                label: item.name,
                            }))}
                            isDisabled={props.isDisabled}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="areas">{t("registerEntry.form.field3.name")}</label>
                    </div>
                    <div>
                        <Select
                            isMulti
                            name="plants"
                            options={props.plants.map((el) => {
                                return {
                                    label: el.name,
                                    value: el._id
                                }
                            })}
                            className="basic-multi-select"
                            placeholder={t("registerEntry.form.field3.placeholder")}
                            components={{IndicatorSeparator: () => null}}
                            isClearable={false}
                            onChange={(e) => {
                                let event = {
                                    name: "plants",
                                    values: e,
                                };
                                onChangeHandler(event);
                            }}
                            value={props.formState.plants.map((item) => ({
                                value: item._id,
                                label: item.name,
                            }))}
                            isDisabled={props.isDisabled}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0"
                                htmlFor="date">{t("registerEntry.form.field4.name")} {!props.isDisabled && '*'}</label>
                    </div>
                    <div>
                        <Input
                            type="datetime-local"
                            name="date"
                            id="date"
                            className="form-control"
                            value={props.formState.date}
                            onChange={onChangeHandler}
                            required
                            disabled={props.isDisabled}
                            style={{maxHeight: '37px', lineHeight: 1.2}}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0"
                                htmlFor="entry-category">{t("registerEntry.form.field5.name")} {!props.isDisabled && '*'}</label>
                    </div>
                    <div>
                        {props.categoryName ?
                            <Input
                                type="text"
                                name="entry-category"
                                id="entry-category"
                                className="form-control input-disabled"
                                value={props.categoryName}
                                disabled
                            />
                        :
                            <Input
                                type='select'
                                name='entry_category'
                                id='entry_category'
                                className='form-select'
                                value={props.formState?.entry_category}
                                onChange={onChangeHandler}
                                required
                            >
                                <option hidden value=''>{t('registerEntry.form.field5.placeholder')}</option>
                                {props.registerEntryCategories?.map((el, id)=>{
                                    return(
                                        <option key={"entry_category-" +id} value={el._id}>{el.label[config.LANGUAGE] ?? el.label.it}</option>
                                    )
                                })}
                            </Input>
                        }
                    </div>
                </div>

                {(props.categoryId === 'fertilization_biostimulation_interventions_um' || props.categoryId === 'pruning' || props.categoryId ===  'release_beneficial_insects' || props.categoryId === 'potted_plants' || props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0"
                                    htmlFor="vegetative_phase">{t("registerEntry.form.field7.name")}</label>
                        </div>
                        <div>
                            <Input
                                type="text"
                                name="vegetative_phase"
                                id="vegetative_phase"
                                className="form-control input-disabled"
                                value={props.formState.vegetative_phase}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                :""}

                {(props.categoryId === 'potted_plants') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="intervention_mode_type">{t("registerEntry.form.field15.name")}</label></div>
                        <div>
                            <Input
                                type='select'
                                name='intervention_mode_type'
                                id='intervention_mode_type'
                                className='form-select'
                                value={props.formState.intervention_mode_type}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            >
                                <option hidden value=''>{t('registerEntry.form.field15.placeholder')}</option>
                                {props.interventionTypes?.map((el, id)=>{
                                    return(
                                        <option key={"intervention_mode_type-" +id} value={el._id}>{el.label[config.LANGUAGE] ?? el.label.it}</option>
                                    )
                                })}
                            </Input>
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'pruning') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="pruning_type">{t("registerEntry.form.field8.name")}</label></div>
                        <div>
                            <Input
                                type="text"
                                name="pruning_type"
                                id="pruning_type"
                                className="form-control input-disabled"
                                value={props.formState.pruning_type}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                : ""}

                {(props.categoryId === 'fertilization_biostimulation_interventions_um' || props.categoryId ===  'release_beneficial_insects' || props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="intervention_reason">{t(props.categoryId === 'phytosanitary_treatment_log' ? "registerEntry.form.field16.name" : "registerEntry.form.field10.name")}</label></div>
                        <div>
                            <Input
                                type="text"
                                name="intervention_reason"
                                id="intervention_reason"
                                className="form-control input-disabled"
                                value={props.formState.intervention_reason}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'fertilization_biostimulation_interventions_um' || props.categoryId ===  'release_beneficial_insects') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="product_type">{t("registerEntry.form.field11.name")}</label></div>
                        <div>
                            <Input
                                type="text"
                                name="product_type"
                                id="product_type"
                                className="form-control input-disabled"
                                value={props.formState.product_type}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'fertilization_biostimulation_interventions_um') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="label_dose">{t("registerEntry.form.field12.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="label_dose"
                                id="label_dose"
                                className="form-control input-disabled"
                                value={props.formState.label_dose}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'fertilization_biostimulation_interventions_um') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="effective_dose">{t("registerEntry.form.field13.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="effective_dose"
                                id="effective_dose"
                                className="form-control input-disabled"
                                value={props.formState.effective_dose}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'release_beneficial_insects') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="m2_dosage">{t("registerEntry.form.field14.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="m2_dosage"
                                id="m2_dosage"
                                className="form-control input-disabled"
                                value={props.formState.m2_dosage}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="extension_ha">{t("registerEntry.form.field17.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="extension_ha"
                                id="extension_ha"
                                className="form-control input-disabled"
                                value={props.formState.extension_ha}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="adversity">{t("registerEntry.form.field18.name")}</label></div>
                        <div>
                            <Input
                                type="text"
                                name="adversity"
                                id="adversity"
                                className="form-control input-disabled"
                                value={props.formState.adversity}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="product_commercial_name">{t("registerEntry.form.field19.name")}</label></div>
                        <div>
                            <Input
                                type="text"
                                name="product_commercial_name"
                                id="product_commercial_name"
                                className="form-control input-disabled"
                                value={props.formState.product_commercial_name}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="quantity">{t("registerEntry.form.field20.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="quantity"
                                id="quantity"
                                className="form-control input-disabled"
                                value={props.formState.quantity}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}

                {(props.categoryId === 'phytosanitary_treatment_log') ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="used_solution">{t("registerEntry.form.field21.name")}</label></div>
                        <div>
                            <Input
                                type="number"
                                inputMode="numeric"
                                name="used_solution"
                                id="used_solution"
                                className="form-control input-disabled"
                                value={props.formState.used_solution}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    : ""}


                {props.categoryId ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0"
                                    htmlFor="operator">{t("registerEntry.form.field9.name")}</label>
                        </div>
                        <div>
                            <Input
                                type="text"
                                name="operator"
                                id="operator"
                                className="form-control input-disabled"
                                value={props.formState.operator}
                                onChange={onChangeHandler}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                :""}

                {props.categoryId ?
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="title">{t("registerEntry.form.field6.name")}</label>
                        </div>
                        <div className='form-limitation-container'>
                        <Input
                                type="textarea"
                                name="notes"
                                id="notes"
                                className="form-control"
                                value={props.formState.notes}
                                onChange={onChangeHandler}
                                placeholder={props.isDisabled ? "" : t("registerEntry.form.field6.placeholder")}
                                disabled={props.isDisabled}
                                rows={3}
                            />
                            {!props.isDisabled && <div>{props.formState.notes.length}/500</div>}
                        </div>
                    </div>
                :""}

                {(props.isDisabled && !props?.fileState?.length) || !props.categoryId ?
                    "" :
                    <div className="mt-5">
                        <div className="mb-4">
                            <div className='font-size-14 fw-medium'>{t("registerEntry.form.images.topic")}</div>
                            {!props.isDisabled ? <div className='text-semi-dark mt-2'>{t("registerEntry.form.images.description")}</div> : ""}
                        </div>

                        {!props.isDisabled ? <Button disabled={props.fileState.length >= 5} color='primary' type="button" onClick={() => imgPickerRef.current.click()} className='me-2 mt-2 mb-3'>{t('main.chooseFiles')}</Button> :""}

                        <Input
                            innerRef={imgPickerRef}
                            type='file'
                            name='image'
                            id='image'
                            className='form-control'
                            onChange={onChangeHandlerFile}
                            accept=".jpg, .jpeg, .png"
                            multiple
                            onClick={(event) => {
                                event.target.value = null
                            }}
                            style={{display: "none"}}
                        />

                        <DraggableImageViewer isDisabled={props.isDisabled} imageData={props.fileState} setImageData={props.setFileState}/>
                    </div>
                }
            </Row>
            <div className="col-12 text-end mt-3 form-btn-grp">
                {!props.isDisabled && <Button disabled={props.fileState.length > 5} color="primary" type="submit" className="me-2">{t("main.save")}</Button>}
                <Button onClick={onBackClick} type="button"
                        className="btn-secondary">{props.isDisabled ? t("main.back") : t("main.cancel")}</Button>
            </div>
        </form>
    );
}

export default OrchardRegisterForm;
