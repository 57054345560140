import React, {useState, useEffect} from "react";
import {Button, Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";

import TableFirst from "../components/tables/TableFirst";
import Layout from "../layout/layout";
import Breadcrumb from "../components/common/Breadcrumb";

import DeleteModal from "../components/modals/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteSystemPlant, getSystemPlantList} from "../redux/actions/systemPlantActions";
import {
    deleteAllNotification,
    deleteNotification,
    getNotificationList, getUnreadCount, markAllAsRead,
    markAsReadNotification
} from "../redux/actions/notificationActions";
import moment from "moment";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import {config} from "../config";

function Notifications() {

    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [modalShowAll, setModalShowAll] = useState(false);
    const [deleteId, setDeleteId] = useState(null)

    const available = useSelector((el) => el.systemData.availableList)
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const notificationData = useSelector((el)=> el.notificationData)

    useEffect(() => {
        dispatch(getNotificationList(1))
    }, [])

    useEffect(() => {
        dispatch(getUnreadCount())
    }, [JSON.stringify(notificationData)])

    const getDataList=(page)=>{
        dispatch(getNotificationList(page))
    }

    const onDeleteNotification=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteNotification(id))
    }

    const onDeleteAll=()=>{
        setModalShowAll(false)
        dispatch(deleteAllNotification())
    }

    const onMarkAllAsRead=()=>{
        dispatch(markAllAsRead())
    }

    const setNotificationStatus = (data) => {
        dispatch(markAsReadNotification(!data.read, data._id))
    };

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            style: {paddingLeft: '0'},
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="notification-details-container">
                        <div onClick={() => setNotificationStatus(row)} className="mt-2">
                            <i className={row.read ? "bx bx-right-arrow-circle text-new-dark" : 'bx bxs-right-arrow-circle text-new-primary'}
                               style={{fontSize: '1.3rem', cursor: 'pointer'}}/>
                        </div>
                        <div>
                            {moment(row.time).format('DD MMM')}
                            <br/>
                            {moment(row.time).format('HH:mm')}
                        </div>
                        <div>
                            <div>{row.system && row.system.name ? row.system.name : available.find((el)=> el._id === row.system)?.name}</div>
                            <div>{row.message?.config?.LANGUAGE ? row.message[row.message.config.LANGUAGE] : row.message[Object.keys(row.message)[0]]}</div>
                        </div>
                    </div>
                );
            },
        },

        {
            key: "btn-set",
            text: "",
            dataField: "name-2",
            style: {paddingRight: "2px"},
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex justify-content-end align-items-center">
                        <div className={"me-4 rounded-pill pill-" + row.level.toLowerCase()}>
                            {capitalizeFirstLetter(row.level)}
                        </div>
                        {(TARGET_CODE !== "VLM_LOCAL") &&
                            <div>
                                <Button onClick={()=> setDeleteId(row._id)} color="danger" className="ms-2">{t("main.delete")}</Button>
                            </div>
                        }
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div className='notification-container'>
                <Breadcrumb
                    title={t('notifications.title')}
                    breadcrumbItems={[
                        {path: `notifications`, name: t('notifications.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        {TARGET_CODE != 'VLM_LOCAL' &&
                            <div className="col-12 text-end mt-3 form-btn-grp pe-3">
                                <Button onClick={onMarkAllAsRead} type="button" color="primary" className="me-2">{t("notifications.markRead")}</Button>
                                <Button onClick={() => setModalShowAll(true)} color="danger">{t("notifications.deleteAll")}</Button>
                            </div>
                        }
                        <TableFirst
                            dataList={notificationData.notificationList}
                            columns={tableColumns}
                            total={notificationData.totalSize}
                            getDataList={getDataList}
                            containerClass={"notification-table-container"}
                            rowBorder={true}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteAll}
                deleteAll={true}
                modalShow={modalShowAll}
                setModalShow={setModalShowAll}
            />

            <DeleteModal
                onDelete={onDeleteNotification}
                deleteAll={false}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />
        </Layout>
    );
}

export default Notifications;
