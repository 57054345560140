export const GET_AVAILABLE_SWITCH_DEVICES_LIST_REQUEST = "GET_AVAILABLE_SWITCH_DEVICES_LIST_REQUEST";
export const GET_AVAILABLE_SWITCH_DEVICES_LIST_SUCCEED = "GET_AVAILABLE_SWITCH_DEVICES_LIST_SUCCEED";
export const GET_AVAILABLE_SWITCH_DEVICES_LIST_FAILED = "GET_AVAILABLE_SWITCH_DEVICES_LIST_FAILED";

export const GET_AVAILABLE_MOISTURE_DEVICES_LIST_REQUEST = "GET_AVAILABLE_MOISTURE_DEVICES_LIST_REQUEST";
export const GET_AVAILABLE_MOISTURE_DEVICES_LIST_SUCCEED = "GET_AVAILABLE_MOISTURE_DEVICES_LIST_SUCCEED";
export const GET_AVAILABLE_MOISTURE_DEVICES_LIST_FAILED = "GET_AVAILABLE_MOISTURE_DEVICES_LIST_FAILED";

export const GET_SYSTEM_USER_LIST_REQUEST = "GET_SYSTEM_USER_LIST_REQUEST";
export const GET_SYSTEM_USER_LIST_SUCCEED = "GET_SYSTEM_USER_LIST_SUCCEED";
export const GET_SYSTEM_USER_LIST_FAILED = "GET_SYSTEM_USER_LIST_FAILED";

export const GET_IRRIGATION_MANAGEMENT_DATA_REQUEST = "GET_IRRIGATION_MANAGEMENT_DATA_REQUEST";
export const GET_IRRIGATION_MANAGEMENT_DATA_SUCCEED = "GET_IRRIGATION_MANAGEMENT_DATA_SUCCEED";
export const GET_IRRIGATION_MANAGEMENT_DATA_FAILED = "GET_IRRIGATION_MANAGEMENT_DATA_FAILED";

export const UPDATE_IRRIGATION_MANAGEMENT_REQUEST = "UPDATE_IRRIGATION_MANAGEMENT_REQUEST";
export const UPDATE_IRRIGATION_MANAGEMENT_SUCCEED = "UPDATE_IRRIGATION_MANAGEMENT_SUCCEED";
export const UPDATE_IRRIGATION_MANAGEMENT_FAILED = "UPDATE_IRRIGATION_MANAGEMENT_FAILED";