import React, { useState } from "react"
import { Row, Col, CardBody, Card, Container, Input } from "reactstrap"
import {useTranslation} from "react-i18next";
import { useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom';
import { ReactComponent as LoginLogo } from '../assets/icons/login-logo.svg';


// import logo from "../assets/images/login_logo.svg"

import { config } from '../config';
import {forgotPassword} from '../redux/actions/authActions'


const ForgotPassword = () => {

  const TARGET_CODE = config.TARGET_CODE;
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState('')

  const handleValidSubmit = (e) => {
    e.preventDefault();

    dispatch(forgotPassword(userEmail))
      .then((data)=>{
        if(data){
          history.push(`/login?type=forgotPassword`)
        }
      })
  }  

  return (
    <React.Fragment>
      <Container className='login-container'>
        <Row style={{minHeight:'100vh'}} className='align-items-center m-0 p-0'>            
          
          <Col md={4} className='p-0 ps-2' style={{zIndex:1}}>
            <Card className="overflow-hidden m-5">
              <div className="bg-primary bg-soft">
                <Row>                    
                  <div className="text-primary p-4 text-start">
                    <h5 className="text-primary">{t("forgotPassword.welcomeMessage")}</h5>
                    <p>{t("forgotPassword.description")}</p>
                  </div>                    
                </Row>
              </div>

              <CardBody className="pt-0">
                <div className="avatar-md mb-4" style={{marginTop:'-26px'}}>
                  <LoginLogo className="avatar-title rounded-circle"/>
                  {/* <span className="avatar-title rounded-circle bg-light">
                    <img src={logo} alt="Login logo" className="rounded-circle" height="34"/>
                  </span> */}
                </div>

                <div className="p-2 pt-1">
                  <form className="form-horizontal" onSubmit={handleValidSubmit}>

                    <div className="mb-3 text-start">
                      <label className='mb-2' style={{fontWeight: 500}}
                             htmlFor='email'>{t("forgotPassword.form.field1.name")}</label>
                      <Input
                          name="email"
                          className="form-control"
                          placeholder={t("forgotPassword.form.field1.placeholder")}
                          type="email"
                          required
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                      >
                        {t("forgotPassword.form.button")}
                      </button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md={8} className='login-img'>
            <div style={{position:'relative'}}>
              <img style={{width:'100%', objectFit:'cover', height:'100%'}} src={`/images/${TARGET_CODE === 'PLT' ? 'plt' : 'vlm'}/login_bg.png`} alt='Background'/>
              { TARGET_CODE.includes('VLM') &&
                  <div className='login-bg-2'>
                    <img src='/images/vlm/login_bg2.png' alt='Background 2'/>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ForgotPassword;