import React, {useEffect, useState} from "react"
import {Row, Col, CardBody, Card, Container, Input, Alert} from "reactstrap"
import {useTranslation} from "react-i18next";
import { useDispatch } from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { ReactComponent as LoginLogo } from '../assets/icons/login-logo.svg'

import { ReactComponent as Lock } from '../assets/icons/lock.svg'

// import logo from "../assets/images/login_logo.svg"

import { config } from '../config';
import { userLogin } from '../redux/actions/authActions'


const Login = () => {

  const TARGET_CODE = config.TARGET_CODE;
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [formState, setFormState] = useState({
    username:'',
    password:'',
    remember:false
  });
  const [alert, setAlert] = useState('')

  useEffect(() => {
    if(searchParams.get('type')) {
      history.push('/login')
      setAlert(t(searchParams.get('type')?.includes("reset") ? "resetPassword.alert" : "forgotPassword.alert"))
      setTimeout(() => {
        setAlert('')
      }, 4000)
    }
  }, []);

  const onChangeHandler=(e)=>{
    const {name, value} = e.target;
    setFormState({
      ...formState,
      [name]:e.target.type === 'checkbox' ? !formState.remember : value
    })
  }

  const handleValidSubmit = (e) => {
    e.preventDefault();

    let data = {};

    data.password = formState.password;
    data.username = formState.username;

    dispatch(userLogin(data, formState.remember))
      .then((data)=>{
        if(data.token){
            history.go(0)
        }
      })
  }  

  return (
    <React.Fragment>
      {alert && <Alert style={{position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 1000, textAlign: 'right'}} color='success'>{alert}</Alert>}

      <Container className='login-container'>
        <Row style={{minHeight:'100vh'}} className='align-items-center m-0 p-0'>            
          
          <Col md={4} className='p-0 ps-2' style={{zIndex:1}}>
            <Card className="overflow-hidden m-5">
              <div className="bg-primary bg-soft">
                <Row>                    
                  <div className="text-primary p-4 text-start">
                    <h5 className="text-primary">{t("login.welocomeMessage")}!</h5>
                    <p>{t("login.message")}</p>
                  </div>                    
                </Row>
              </div>

              <CardBody className="pt-0">
                <div className="avatar-md mb-4" style={{marginTop:'-26px'}}>
                  <LoginLogo className="avatar-title rounded-circle"/>
                  {/* <span className="avatar-title rounded-circle bg-light">
                    <img src={logo} alt="Login logo" className="rounded-circle" height="34"/>
                  </span> */}
                </div>

                <div className="p-2 pt-1">
                  <form className="form-horizontal" onSubmit={handleValidSubmit}>

                    <div className="mb-3 text-start">
                      <label className='mb-2' style={{fontWeight: 500}}
                             htmlFor='username'>{t("login.form.field1.name")}</label>
                      <Input
                          name="username"
                          className="form-control"
                          placeholder={t("login.form.field1.placeholder")}
                          type="email"
                          required
                          value={formState.username}
                          onChange={onChangeHandler}
                      />
                    </div>

                    <div className="mb-3 text-start">
                      <label className='mb-2' style={{fontWeight: 500}}
                             htmlFor='password'>{t("login.form.field2.name")}</label>
                      <Input
                          name="password"
                          type="password"
                          required
                          placeholder={t("login.form.field2.placeholder")}
                          value={formState.password}
                          onChange={onChangeHandler}
                      />
                    </div>

                    <div className="form-check  text-start" style={{fontWeight: 500}}>
                      <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          name='remember'
                          checked={formState.remember}
                          onChange={onChangeHandler}
                      />
                      <label className="form-check-label" htmlFor="customControlInline">
                        {t("login.form.field3.placeholder")}
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                      >
                        {t("login.form.button")}
                      </button>
                    </div>

                    <div className="text-center mt-3">
                      <Link to={`/forgot-password`} className="text-new-dark">
                        <Lock className="me-1"/> {t("login.form.forgotPassword")}
                      </Link>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md={8} className='login-img'>
            <div style={{position:'relative'}}>
              <img style={{width:'100%', objectFit:'cover', height:'100%'}} src={`/images/${TARGET_CODE === 'PLT' ? 'plt' : 'vlm'}/login_bg.png`} alt='Background'/>
              { TARGET_CODE.includes('VLM') &&
                  <div className='login-bg-2'>
                    <img src='/images/vlm/login_bg2.png' alt='Background 2'/>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Login;