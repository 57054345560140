import {ReactComponent as Droplet} from '../assets/icons/droplet.svg'
import {ReactComponent as Bulb} from '../assets/icons/lightbulb.svg'
import {ReactComponent as Temperature} from '../assets/icons/temperature.svg'
import {ReactComponent as Bolt} from '../assets/icons/bolt.svg'
import { ReactComponent as CircleIcon } from '../assets/icons/circleloaderfive.svg'
import { ReactComponent as BatteryFull } from '../assets/icons/battery-full.svg'
import { ReactComponent as BatteryThreeQuarters } from '../assets/icons/battery-three-quarters.svg'
import { ReactComponent as BatteryHalf } from '../assets/icons/battery-half.svg'
import { ReactComponent as BatteryQuarter } from '../assets/icons/battery-quarter.svg'
import { ReactComponent as BatteryEmpty } from '../assets/icons/battery-empty.svg'

const DEFAULT_ICON = <CircleIcon/>

const deviceIcons = {
    "temperature-sensor": () => <Temperature/>,
    "humidity-sensor": () => <Droplet/>,
    "soil-moisture-sensor": () => <Droplet/>,
    "switch": (state) => <Bolt className={state === 'on' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>,
    "light": (state) => <Bulb className={state === 'on' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>,
    "battery-level": (state) => getBatteryIcon(state),
}

function getDeviceIcon(type, stateValue) {
    try {
        type = type.toLowerCase().trim();
        stateValue = stateValue?.toLowerCase().trim() || "";

        return deviceIcons[type] ? deviceIcons[type](stateValue) : DEFAULT_ICON;   
    } catch (error) {
        return DEFAULT_ICON;
    }
}

function getBatteryIcon(state) {
    // Define color classes
    const grayClass = "text-semi-dark-svg";
    const successClass = "text-success-svg";
    const warningClass = "text-warning-svg";
    const dangerClass = "text-danger-svg";
    // Parse value
    const stateValue = parseInt(state);
    if(isNaN(stateValue)) {
        return <BatteryFull className={grayClass}/>
    }
    // Return the right icon and color
    if(stateValue > 75) {
        // 76-100 full success
        return <BatteryFull className={successClass}/>
    }
    else if(stateValue > 50) {
        // 51-75 3 quarters success
        return <BatteryThreeQuarters className={successClass}/>
    }
    else if(stateValue > 25) {
        // 26-50 half warning
        return <BatteryHalf className={warningClass}/>
    }
    else if(stateValue > 5) {
        // 6-25 quarter danger
        return <BatteryQuarter className={dangerClass}/>
    }
    else {
        // 0-5 empty danger
        return <BatteryEmpty className={dangerClass}/>
    }
}

export default getDeviceIcon;