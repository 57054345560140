import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import jsYaml from "js-yaml";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import RuleForm from "../../components/forms/RuleForm";

import {addRule, getRuleData, updateRule} from "../../redux/actions/ruleActions";


const RuleAddEdit = ({isEdit, isDisabled}) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.authData?.userDetails)
    const available = useSelector((el) => el.systemData.availableList)
    const ruleData = useSelector((el) => el.ruleData.ruleData)
    const rules = useSelector((el) => el.ruleData.availableRules)
    const errorData = useSelector((el) => el.loadingData.error)

    const [formState, setFormState] = useState({
        rule: null,
        configName: '',
        configDescription: '',
        ruleParameters:'',
        triggerParameters:'',
    })

    useEffect(() => {
        if(userDetails?.role === 'supervisor' && !userDetails?.model_modification_allowance) {
            history.push(`/systems`)
        } else if (isEdit || isDisabled) {
            if (!available.some((el) => el._id === params.systemId)) {
                history.push(`/systems`)
            } else {
                dispatch(getRuleData(params.ruleId, params.systemId))
            }
        } else {
            let rule = rules.find((el) => el._id === params.ruleConfigId)
            setFormState({
                ...formState,
                rule: rule,
                ruleParameters: jsYaml.dump(rule?.ruleparams_sample),
                triggerParameters: jsYaml.dump(rule?.trigger?.triggerparams_sample)
            })

        }
    }, [])
    useEffect(() => {
        if (ruleData && (isEdit || isDisabled)) {
            setFormState({
                _id: ruleData._id,
                configName: ruleData.name,
                configDescription: ruleData.description || '',
                rule: ruleData.rule,
                status: ruleData.status,
                triggerParameters: jsYaml.dump(ruleData.triggerparams),
                ruleParameters: jsYaml.dump(ruleData.ruleparams),
            })
        }
    }, [ruleData])

    useEffect(() => {
        if (errorData && errorData.code === 404) {
            history.push(`/system/${params.systemId}/rules`)
        }
    }, [errorData])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.configName;
        data.description = formState.configDescription;
        data.rule = formState.rule?._id;
        data.triggerparams = jsYaml.load(formState.triggerParameters, null)
        data.ruleparams = jsYaml.load(formState.ruleParameters, null)

        if(formState._id){
            data._id = formState._id;
            dispatch(updateRule(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/rules`)
                }
            })
        } else {
            dispatch(addRule(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/rules`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('rules.editRule') : isDisabled ? formState.configName : t('rules.addRule')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/rules`, name: t('rules.breadcrumb1')},
                        {
                            path: isEdit ? `system/${params.systemId}/rule/edit/${params.ruleId}` :  isDisabled ?  `system/${params.systemId}/rule/view/${params.ruleId}` : `system/${params.systemId}/rule/add/rule-config/${params.ruleConfigId}`,
                            name: t(isEdit ? 'rules.breadcrumbEdit' : isDisabled ? formState.configName : 'rules.breadcrumbAdd')
                        }
                    ]}
                />

                <Card>
                    <CardBody>
                        <RuleForm
                            isDisabled={isDisabled}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default RuleAddEdit;