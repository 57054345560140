import React, {useEffect, useState} from "react";
import {Card, CardBody, Button, Input, Alert} from "reactstrap";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../components/common/Breadcrumb";
import Layout from "../layout/layout";
import {useDispatch, useSelector} from "react-redux";
import {updateMyDetails} from "../redux/actions/authActions";
import {pageLoadStop} from "../redux/actions/loadingActions";

function Settings() {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.authData?.userDetails)

    const [profile, setProfile] = useState({
        firstName: "John",
        lastName: "Doe",
        email: "j.doe@mail.com",
        password: "",
        confirmPassword: "",
    });
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(()=>{
        setTimeout(()=>{
            dispatch(pageLoadStop())
        }, 500)
    },[])

    useEffect(()=>{
        if(userDetails){
            setProfile({
                ...profile,
                firstName: userDetails?.firstname,
                lastName: userDetails?.lastname,
                email: userDetails?.email,
            })
        }
    },[userDetails])

    const onChangeHandler = (e) => {
        let newProfile = {
            ...profile,
            [e.target.name]: e.target.value
        }

        setProfile(newProfile);
    };

    const onSubmit = (e, id) => {
        e.preventDefault();

        let data = {};
        data._id = userDetails._id;

        if(id === 1){
            data.firstname = profile.firstName;
            data.lastname = profile.lastName;
        } else if (id === 2){
            data.email = profile.email
        } else {
            data.password = profile.password;
            data.confirmpassword = profile.confirmPassword;
        }

        dispatch(updateMyDetails(data)).then((res)=>{
            if(res){
                setAlertMessage(t('main.success'));
                setTimeout(()=>{
                    setAlertMessage("")
                }, 2000)
            }
        })
    };

    return (
        <Layout>
            <div className="pt-4 pb-3">

                {alertMessage && <Alert style={{position:'fixed', width:'100%', top:0, left:0, zIndex:1000, textAlign:'center'}} color='success'>{alertMessage}</Alert>}

                <Breadcrumb
                    title={t('settings.title')}
                    breadcrumbItems={[
                        {path: `settings`, name: t('settings.breadcrumb1')}
                    ]}
                />

                <div>
                    <Card>
                        <CardBody className="settings-profile-container settings-container">
                            <div>
                                <div className='font-size-15 fw-medium'>{t("settings.card1.topic")}</div>
                                <div className='text-semi-dark mt-2'>{t("settings.card1.subTopic")}</div>
                            </div>
                            <form onSubmit={(e)=>onSubmit(e, 1)}>
                                <div className="mt-4 row ">
                                    <div className='col-6'>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            className="form-control"
                                            value={profile.firstName}
                                            onChange={onChangeHandler}
                                            required
                                            placeholder={t("settings.card1.form2")}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            className="form-control"
                                            value={profile.lastName}
                                            onChange={onChangeHandler}
                                            required
                                            placeholder={t("settings.card1.form1")}
                                        />
                                    </div>
                                </div>
                                <div className='mt-3'><Button color="primary" className="mb-2"
                                                              type="submit">{t("settings.card1.button")}</Button></div>
                            </form>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody className="settings-container">
                            <div>
                                <div className='font-size-15 fw-medium'>{t("settings.card2.topic")}</div>
                                <div className='text-semi-dark mt-2'>{t("settings.card2.subTopic")}</div>
                            </div>
                            <form onSubmit={(e)=>onSubmit(e, 2)}>
                                <div className="mt-4 mb-3">
                                    <div>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="form-control"
                                            value={profile.email}
                                            onChange={onChangeHandler}
                                            required
                                            placeholder={t("settings.card2.form1")}
                                        />
                                    </div>
                                </div>
                                <div><Button color="danger" className="mb-2"
                                             type="submit">{t("settings.card2.button")}</Button></div>
                            </form>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody className="settings-container">
                            <div>
                                <div className='font-size-15 fw-medium'>{t("settings.card3.topic")}</div>
                                <div className='text-semi-dark mt-2'>{t("settings.card3.subTopic")}</div>
                            </div>
                            <form onSubmit={(e)=>onSubmit(e, 3)}>
                                <div className="mt-4 mb-3">
                                    <div className="mb-2">
                                        <Input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="form-control"
                                            value={profile.password}
                                            onChange={onChangeHandler}
                                            required
                                            placeholder={t("settings.card3.form1")}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            className="form-control"
                                            value={profile.confirmPassword}
                                            onChange={onChangeHandler}
                                            required
                                            placeholder={t("settings.card3.form2")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button color="danger" className="mb-2"
                                            type="submit">{t("settings.card3.button")}</Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Layout>
    );
}

export default Settings;
