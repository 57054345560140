import { irrigationManagementActionTypes } from "../actionTypes/index";
import produce from "immer";

const initialState = {
    switchDeviceOptions: [],
    moistureDeviceOptions: [],
    systemUserOptions: [],
    irrigationManagementData: null,
};

const systemData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case irrigationManagementActionTypes.GET_AVAILABLE_SWITCH_DEVICES_LIST_SUCCEED: {
                state.switchDeviceOptions = payload?.devices ?? [];
                return state;
            }
            case irrigationManagementActionTypes.GET_SYSTEM_USER_LIST_SUCCEED: {
                state.systemUserOptions = payload ?? [];
                return state;
            }
            case irrigationManagementActionTypes.GET_AVAILABLE_MOISTURE_DEVICES_LIST_SUCCEED: {
                state.moistureDeviceOptions = payload?.devices ?? [];
                return state;
            }
            case irrigationManagementActionTypes.GET_IRRIGATION_MANAGEMENT_DATA_SUCCEED: {
                state.irrigationManagementData = payload?.uiConfig;
                return state;
            }
            case irrigationManagementActionTypes.UPDATE_IRRIGATION_MANAGEMENT_SUCCEED: {
                state.irrigationManagementData = payload;
                return state;
            }


            default: {
                return state;
            }
        }
    }
);

export default systemData;
