import React from 'react';
import {Row, Col, Container} from 'reactstrap';

import VlmLogo from '../../assets/images/logo.png'
import PltLogo from '../../assets/images/plt_logo.png'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { config } from '../../config';

const Header = (props) => {

    const TARGET_CODE = config.TARGET_CODE;
    const {t} = useTranslation();

    return (
        <Container fluid={true}>
            <Row className='header-container justify-content-inbetween ps-1 pe-1 ps-sm-3 pe-sm-3 pt-3'>
                <Col className="text-start">
                    <span>
                        {TARGET_CODE === 'PLT' ? <img style={{height: 22, maxWidth: '100%', maxHeight: '100%', marginLeft: -5}} src={PltLogo} alt='PlantCare 5.0 Logo'/> : <img style={{height: 20, maxWidth: '100%', maxHeight: '100%', marginLeft: -5}} src={VlmLogo} alt='Velmare Logo'/> }
                    </span>
                </Col>
                <Col className="text-end">
                    {TARGET_CODE !== 'VLM_LOCAL' &&
                        <Link to={`/settings`}><i className='bx bx-cog bx-xs me-2 text-white'/></Link>}
                    <Link to={`/notifications`}>
						<span style={{position: 'relative', cursor: 'pointer'}} className='noti-icon me-2 ms-1'>
                            <i className={'bx bx-bell bx-xs text-white ' + (props.unreadCount > 0 ? 'bx-tada' : '')}/>
                            {props.unreadCount > 0 &&
                                <span className='badge bg-danger rounded-pill'>{props.unreadCount}</span>}
                        </span>
                    </Link>
                    {TARGET_CODE.includes('VLM') && <i style={{cursor: 'pointer'}} onClick={() => {
                        props.setAboutmodal(true)
                    }} className='bx bx-error-circle bx-xs text-white ms-1 me-2'/>}

                    <i onClick={props.logout} style={{cursor: 'pointer'}} className='bx bx-log-out bx-rotate-180 bx-xs ms-1 text-white'/>
                </Col>
            </Row>
        </Container>
    )
}

export default Header;