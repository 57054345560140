import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

const PrivacyPolicy = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch("/html/privacy_policy.html")
            .then((response) => response.text())
            .then((html) => setContent(html))
            .catch((error) =>
                console.error(
                    "Error loading privacy policy",
                    error
                )
            );
    }, []);

    return (
        <React.Fragment>
            <Container>
                <div className="pt-4 pb-4" dangerouslySetInnerHTML={{ __html: content }} />
            </Container>
        </React.Fragment>
    )
};

export default PrivacyPolicy;
