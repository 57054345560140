import React, {useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import TableFirst from "../../components/tables/TableFirst";
import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";

import { ReactComponent as Book } from '../../assets/icons/book.svg'

import {
    getRegisterEntriesCategoriesList
} from "../../redux/actions/orchardRegisterActions";
import {config} from "../../config";

const RegisterEntryCategoryList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getRegisterEntriesCategoriesList())
        }
    }, [location.pathname])


    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Book className='pe-4 tap-icon'/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.label[config.LANGUAGE] ?? row.label.it}</div>
                            <div>
                                <div className='fw-light text-semi-dark font-size-12'>{row?.number_of_interventions} {t("registerEntry.interventions")} - {t("main.lastUpdate")}: {row.latestEntry ? moment(row.date).format('YYYY-MM-DD, HH:mm') : "-"}</div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/register-entries/category/${row._id}/`}><Button color="primary">{t("registerEntry.viewInterventions")}</Button></Link>
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('registerEntry.title')}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/register-entries`, name: t('registerEntry.breadcrumb')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div className="d-md-flex justify-content-md-between me-0 me-md-3">
                            <div>
                                <div className='font-size-15 fw-medium'>{t("registerEntry.pageTopic")}</div>
                                <div className='text-semi-dark mt-2 mb-4 text-nowrap'>{t("registerEntry.pageSubTopic")}</div>
                            </div>

                            <div style={{width: 'max-content', marginLeft: 'auto'}} className="mb-4 mb-md-0">
                                <Link to={`/system/${params.systemId}/register-entries/category/general/entry/add`} className="text-decoration-none">
                                    <Button color='success' className='pe-4 ps-3 d-flex align-items-center' style={{borderRadius: 20}}>
                                        <i className='bx bx-plus bx-xs text-white me-2'/>
                                        {t('main.add')} {t("registerEntry.category.add")}
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <TableFirst
                            dataList={orchardRegisterData.registerEntryCategoriesList}
                            columns={tableColumns}
                            containerClass={'header-less-table-container'}
                            rowBorder={true}
                            isStatic={true}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    );
}

export default RegisterEntryCategoryList;
