import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card, Container, Input } from "reactstrap"
import {useTranslation} from "react-i18next";
import { useDispatch } from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import { ReactComponent as LoginLogo } from '../assets/icons/login-logo.svg';


// import logo from "../assets/images/login_logo.svg"

import { config } from '../config';
import {passwordReset} from '../redux/actions/authActions'


const ResetPassword = () => {

  const TARGET_CODE = config.TARGET_CODE;
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [formState, setFormState] = useState({
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    if(!searchParams.get("token")){
      history.push(`/login`)
    }
  }, []);

  const handleValidSubmit = (e) => {
    e.preventDefault();

    dispatch(passwordReset({...formState, token: searchParams.get("token")}))
      .then((data)=>{
        if(data){
          history.push(`/login?type=resetPassword`)
        }
      })
  }  

  return (
    <React.Fragment>
      <Container className='login-container'>
        <Row style={{minHeight:'100vh'}} className='align-items-center m-0 p-0'>            
          
          <Col md={4} className='p-0 ps-2' style={{zIndex:1}}>
            <Card className="overflow-hidden m-5">
              <div className="bg-primary bg-soft">
                <Row>                    
                  <div className="text-primary p-4 text-start">
                    <h5 className="text-primary">{t("resetPassword.welcomeMessage")}</h5>
                    <p>{t("resetPassword.description")}</p>
                  </div>                    
                </Row>
              </div>

              <CardBody className="pt-0">
                <div className="avatar-md mb-4" style={{marginTop:'-26px'}}>
                  <LoginLogo className="avatar-title rounded-circle"/>
                  {/* <span className="avatar-title rounded-circle bg-light">
                    <img src={logo} alt="Login logo" className="rounded-circle" height="34"/>
                  </span> */}
                </div>

                <div className="p-2 pt-1">
                  <form className="form-horizontal" onSubmit={handleValidSubmit}>

                    <div className="mb-3 text-start">
                      <label className='mb-2' style={{fontWeight: 500}}
                             htmlFor='password'>{t("resetPassword.form.field1.name")}</label>
                      <Input
                          name="password"
                          className="form-control"
                          placeholder={t("resetPassword.form.field1.placeholder")}
                          type="password"
                          required
                          value={formState.password}
                          onChange={(e) => setFormState({...formState, password: e.target.value})}
                      />
                    </div>

                    <div className="mb-3 text-start">
                      <label className='mb-2' style={{fontWeight: 500}}
                             htmlFor='confirmPassword'>{t("resetPassword.form.field2.name")}</label>
                      <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder={t("resetPassword.form.field2.placeholder")}
                          type="password"
                          required
                          value={formState.confirmPassword}
                          onChange={(e) => setFormState({...formState, confirmPassword: e.target.value})}
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                      >
                        {t("resetPassword.form.button")}
                      </button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md={8} className='login-img'>
            <div style={{position: 'relative'}}>
              <img style={{width: '100%', objectFit: 'cover', height: '100%'}}
                   src={`/images/${TARGET_CODE === 'PLT' ? 'plt' : 'vlm'}/login_bg.png`} alt='Background'/>
              {TARGET_CODE.includes('VLM') &&
                  <div className='login-bg-2'>
                    <img src='/images/vlm/login_bg2.png' alt='Background 2'/>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ResetPassword;